import axios from 'axios';
import * as endpoints from 'services/api-endpoints';
import { useEffect, useState } from 'react';
import { PERSIST_STORE_NAME } from 'constants/app.constant';
import deepParseJson from 'utils/deepParseJson';
import store from '../store'

const parseParams = params => {
    let options = '';
    for (const key in params) {
        if (typeof params[key] !== 'object' && params[key]) {
            options += `${key}=${params[key]}&`;
        } else if (typeof params[key] === 'object' && params[key] && params[key].length) {
            params[key].forEach(el => {
                options += `${key}=${el}&`;
            });
        }
    }
    return options ? options.slice(0, -1) : options;
};

const api = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_ENDPOINT}`,
    headers: { 'content-type': 'application/json' },
    paramsSerializer: params => parseParams(params)
});

api.interceptors.request.use(async config => {
    //let auth = getToken();
     let auth = null;

    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
    const persistData = deepParseJson(rawPersistData)

    let accessToken = persistData.auth.session.token

    if (!accessToken) {
        const { auth } = store.getState()
        accessToken = auth.session.token
    }

    
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
});

api.interceptors.response.use(
    async (response) => {
        return response;
    }, (error) => {
        const code = error?.response?.status;

        // if (code === 403 || code === 401) {
        //     window.location.href = "/login";
        // }
        // if (error?.response === undefined && error?.request !== undefined) {
            
        //     if (!ERROR_MSG) {
        //         ERROR_MSG = true;
        //         showMessage(":( Ops! Sistema temporariamente indisponível. Tente novamente dentro de alguns minutos.", (m) => toast.error(m, { autoClose: 15000 }));
        //         setTimeout(() => { ERROR_MSG = false; }, ERROR_MSG_DELAY);
        //     }
        //     return undefined;
        // }

        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);

let TEMP_PARAMS = null;
api.getWithDelay = (url, delay = 400, options) => {
    TEMP_PARAMS = JSON.stringify(options);

    return new Promise(
        resolve =>
            setTimeout(
                () => (TEMP_PARAMS === JSON.stringify(options)) ? resolve(api.get(url, options)) : resolve
                , delay
            )
    )
}

export const useFetch = (url, options = {}, deps) => {

    const [state, setState] = useState({ result: null, isLoading: false, hasError: false });

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        if (!url) {
            return
        }

        const fetchData = async () => {
            setState({ ...state, isLoading: true })

            await api
                .get(url, options)
                .then((response) => {
                    if (signal.aborted) {
                        setState({ ...state, isLoading: false })
                        return;
                    }
                    setState({ ...state, result: response.data })
                })
                .catch(function (error) {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        // console.log(error.response.data);
                        // console.log(error.response.status);
                        // console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        // console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        // console.log('Error', error.message);
                    }

                    if (signal.aborted) {
                        setState({ ...state, isLoading: false })
                        return;
                    }

                    setState({
                        result: error.response?.data || error.request || error.message,
                        isLoading: false,
                        hasError: true
                    })
                })
                .then(() => {
                    setState({ ...state, isLoading: false })
                });

        }

        fetchData()

        return () => {
            abortController.abort();
        };
    }, [url, deps])

    return state
}

export default api;
export const endpoint = endpoints;
